import Form, { ButtonItem, ButtonOptions, CompareRule, PatternRule, GroupItem, Item, Label, RequiredRule } from 'devextreme-react/form'
import { useCallback } from 'react'
import { LabelTemplate } from '../../../common'
import { ICreateAccountPropsBase } from '../Model'
import { Link } from 'react-router-dom'
import { Const } from '../../../../const'
import { useConfig } from '../../../../contexts'

const CustomerProfileForm = (props: ICreateAccountPropsBase): JSX.Element => {
    const formData = props
    const allowEdition: boolean = props.allowEdition
    const formDisabled = !allowEdition
    const { commonConfig } = useConfig()
    const customContent = commonConfig.Content.Common
    const currentDate = new Date()
    const calcYear = currentDate.getFullYear() - 18
    const calcMonth = currentDate.getMonth()
    const defaultMonth = calcMonth + 1
    const calcDay = currentDate.getDate()
    let defaultDate = ''

    if (formData.people[0].pii.birthdate === '') {
        defaultDate = `${calcYear}/${defaultMonth}/${calcDay}`
    } else {
        defaultDate = formData.people[0].pii.birthdate
    }
    const maxDate = new Date(calcYear, calcMonth, calcDay)
    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            if (formData.people[0].pii.birthdate === '') {
                formData.people[0].pii.birthdate = defaultDate
            }
            const selectedDOB = new Date(formData.people[0].pii.birthdate)
            const tempDate = selectedDOB.toISOString().split('T')[0]
            const formattedDOB = tempDate.replace(/-/g, '/')
            formData.people[0].pii.birthdate = formattedDOB

            formData.people[0].email = formData.profile.accountName
            const previous = /\+1/g
            const matches = formData.people[0].phoneNumbers[0].phoneNumber.match(previous)
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (!matches) {
                formData.people[0].phoneNumbers[0].phoneNumber = '+1'.concat('', formData.people[0].phoneNumbers[0].phoneNumber)
            }
            e.preventDefault()
            props.nextTab()
        },
        [defaultDate, formData.people, props]
    )
    const birthDateEditorOptions = {
        labelTemplateText: 'Birthdate',
        acceptCustomValue: false,
        openOnFieldClick: true,
        stylingMode: 'filled',
        max: maxDate,
        elementAttr: {
            'field-id': 'birthdate-code-id',
        },
    }
    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} colCount={2} elementAttr={{ 'field-id': 'personal-code-id' }}>
                <Item dataField={'people[0].firstName'} editorType={'dxTextBox'} editorOptions={nameEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="First name is required" />
                    <Label render={LabelTemplate} />
                </Item>

                <Item dataField={'people[0].lastName'} editorType={'dxTextBox'} editorOptions={lastNameEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Last name is required" />
                    <Label render={LabelTemplate} />
                </Item>

                <Item dataField={'profile.accountName'} editorType={'dxTextBox'} editorOptions={emailEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Email is required" />
                    <PatternRule message="Email is not valid" pattern={Const.EmailValidatorPattern} />
                    <Label render={LabelTemplate} />
                </Item>

                <Item dataField={'people[0].phoneNumbers[0].phoneNumber'} editorType={'dxTextBox'} editorOptions={phoneEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Phone number is required" />
                    <Label render={LabelTemplate} />
                </Item>


                <Item dataField={'profile.password'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions} disabled={formDisabled}>
                    <RequiredRule />
                    <PatternRule
                        pattern="^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                        message="At least 8 characters, 1 number, 1 uppercase character, 1 of the following !@#$%^&*"
                    />
                    <Label render={LabelTemplate} />
                </Item>

                <Item dataField={'profile.confirmPassword'} editorType={'dxTextBox'} editorOptions={confirmedPasswordEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Password is required" />
                    <CompareRule message={'Passwords do not match'} comparisonType={'=='} comparisonTarget={() => formData.profile.password} />
                    <Label render={LabelTemplate} />
                </Item>

                <Item dataField={'people[0].pii.birthdate'} editorType={'dxDateBox'} editorOptions={birthDateEditorOptions} disabled={formDisabled}>
                    {/* <RangeRule message="You must be at least 18 years old"  /> */}
                    <RequiredRule message="Birthdate is required" />
                    <Label render={LabelTemplate} />
                </Item>

                <Item dataField={'people[0].pii.ssn'} editorOptions={ssnEditorOptions} editorType={'dxTextBox'} disabled={formDisabled}>
                    <RequiredRule message="Social security number is required" />
                    <Label render={LabelTemplate} />
                </Item>

                {/* Empty item placed here so the form can display properly in 2 columns */}
                {!formDisabled && (
                    <ButtonItem colSpan={2}>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                )}
                {!formDisabled && (
                    <Item>
                        <div className={'login-link'}>
                            {customContent.backToLoginPrefix}
                            <Link to={'/'}> {customContent.backToLoginLinkText}</Link>
                        </div>
                    </Item>
                )}
            </Form>
        </form>
    )
}

const nameEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'First Name',
    mode: 'text',
    icon: false,
    elementAttr: {
        'field-id': 'name-code-id',
    },
}

const lastNameEditorOptions = {
    stylingMode: 'filled',
    mode: 'text',
    labelTemplateText: 'Last Name',
    icon: false,
    elementAttr: {
        'field-id': 'last-name-code-id',
    },
}
const emailEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Email',
    mode: 'email',
    icon: false,
    elementAttr: {
        'field-id': 'email-code-id',
    },
}

const phoneEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'Phone Number',
    icon: false,
    mode: 'text',
    mask: '+1 (X00) 000-0000',
    maskRules: {
        X: /[02-9]/,
    },
    maskInvalidMessage: 'The phone must have a correct USA phone format',
    inputAttr: {
        autoComplete: 'new-phone',
    },
    elementAttr: {
        'field-id': 'phone-code-id',
    },
}

const passwordEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'Password',
    mode: 'password',
    icon: false,
    elementAttr: {
        'field-id': 'password-code-id',
    },
}
const confirmedPasswordEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'Confirm Password',
    mode: 'password',
    icon: false,
    elementAttr: {
        'field-id': 'confirm-password-code-id',
    },
}

const ssnEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'Social Security Number',
    mode: 'text',
    mask: '000000000',
    maskChar: ' ',
    elementAttr: {
        'field-id': 'ssn-code-id',
    },
}

const submitButtonAttributes = { class: 'submit-button', 'field-id': 'submit-code-id' }
export default CustomerProfileForm
