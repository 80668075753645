import { CommonConfigModel, EnvConfigModel } from '../../clients/api/config'

export enum ConfigStateEnum {
    Unloaded = 0,
    Loading = 1,
    Loaded = 2,
}

export class ConfigContextModel {
    constructor(
        public commonConfig: CommonConfigModel = new CommonConfigModel(),
        public loadingCommonConfig: ConfigStateEnum = ConfigStateEnum.Unloaded,
        public envConfig: EnvConfigModel = new EnvConfigModel(),
        public loadingEnvConfig: ConfigStateEnum = ConfigStateEnum.Unloaded,
        public firm: string = '',
        public environment: string = '',
        public imageBaseUrl: string = '',
        public firmImageBaseUrl: string = ''
    ) {}
}
