import axios from 'axios'
import { ConfigStateEnum } from '../../../contexts/config/Model'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ConfigApi {
    public LoadConfig<Type>(url: string, setConfig: (config: Type) => void, setLoading: (configStateEnum: ConfigStateEnum) => void): void {
        axios
            .get(url, {
                timeout: 3000, // Timeout in milliseconds
            })
            .then((response) => {
                setConfig(response.data)
            })
            .catch((err) => {
                console.error(`Cannot get the configuration from '${url}', error: ${JSON.stringify(err)} `)
            })
            .finally(() => {
                setLoading(ConfigStateEnum.Loaded)
            })
    }
}
