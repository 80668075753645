import { useCallback, useRef, useState } from 'react'
import CreateAccountFormIndividualStandardVaultLink from './Forms/IndividualStandard'
import { ProductTypeEnum, ProfileTypeEnum } from '../Model'
import CreateAccountFormIndividualProVaultLink from './Forms/IndividualPro'
import CreateAccountFormBusinessStandardVaultLink from './Forms/BusinessStandard'
import CreateAccountFormBusinessProVaultLink from './Forms/BusinessPro'
import { CreateAccountBusinessProVaultLinkProps } from './Model'
import { UploadDocumentProvider, useConfig } from '../../../../contexts'
import { SingleCard } from '../../../common'

const CreateAccountFormVaultLink = (): JSX.Element => {
    const { commonConfig } = useConfig()

    const [profileType, setProfileType] = useState(ProfileTypeEnum.Individual)
    const [productType, setProductType] = useState(ProductTypeEnum.Standard)

    const data = new CreateAccountBusinessProVaultLinkProps()
    data.profile.productType = productType
    data.profile.profileType = profileType
    const formData = useRef({ ...data })

    const onProfileTypeValueChanged = useCallback(
        (newProfileType: ProfileTypeEnum): void => {
            if (profileType !== newProfileType) {
                setProfileType(newProfileType)
            }
        },
        [profileType]
    )

    const onProductTypeValueChanged = useCallback(
        (newProductType: ProductTypeEnum): void => {
            if (productType !== newProductType) {
                setProductType(newProductType)
            }
        },
        [productType]
    )

    const customContent = commonConfig.Content.SignUp

    return (
        <UploadDocumentProvider>
            <SingleCard {...customContent}>
                {profileType === ProfileTypeEnum.Individual && productType === ProductTypeEnum.Standard ? (
                    <CreateAccountFormIndividualStandardVaultLink
                        onProfileTypeChanged={onProfileTypeValueChanged}
                        onProductTypeChanged={onProductTypeValueChanged}
                        data={formData.current}
                    />
                ) : profileType === ProfileTypeEnum.Individual && productType === ProductTypeEnum.Pro ? (
                    <CreateAccountFormIndividualProVaultLink
                        onProfileTypeChanged={onProfileTypeValueChanged}
                        onProductTypeChanged={onProductTypeValueChanged}
                        data={formData.current}
                    />
                ) : profileType === ProfileTypeEnum.Business && productType === ProductTypeEnum.Standard ? (
                    <CreateAccountFormBusinessStandardVaultLink
                        onProfileTypeChanged={onProfileTypeValueChanged}
                        onProductTypeChanged={onProductTypeValueChanged}
                        data={formData.current}
                    />
                ) : profileType === ProfileTypeEnum.Business && productType === ProductTypeEnum.Pro ? (
                    <CreateAccountFormBusinessProVaultLink
                        onProfileTypeChanged={onProfileTypeValueChanged}
                        onProductTypeChanged={onProductTypeValueChanged}
                        data={formData.current}
                    />
                ) : (
                    <p>
                        Product Type:{profileType} and Product Type:{productType} UNSUPPORTED!
                    </p>
                )}
            </SingleCard>
        </UploadDocumentProvider>
    )
}

export default CreateAccountFormVaultLink
