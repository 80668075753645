import Form, { ButtonItem, ButtonOptions, Item, GroupItem } from 'devextreme-react/form'
import { useCallback, useState } from 'react'
import { ICreateAccountPropsBase, IQuestion, Question } from '../Model'
import { CheckBox, DataGrid } from 'devextreme-react'
import { Column, Editing, Lookup, Paging, RequiredRule } from 'devextreme-react/data-grid'
import { useConfig } from '../../../../contexts'
import { DAW, initializeDAW } from './DAW'

const WalletIntakeForm = (props: ICreateAccountPropsBase): JSX.Element => {
    const formData = props
    const { commonConfig } = useConfig()
    const allowEdition: boolean = props.allowEdition
    const formDisabled = !allowEdition
    const [showWalletIntake, setshowWalletIntake] = useState(false)
    const customContent = commonConfig.Content.SignUp
    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            e.preventDefault()
            // NOTE - This causes an error when handling an individual pro account.
            // if (formData.profile?.profileType === ProfileTypeEnum.Business) formData.people.length = 2
            if (daws !== undefined && daws.length > 0) {
                daws.forEach((daw) => {
                    const tempArray: IQuestion[] = []
                    tempArray?.push(
                        new Question(3, 1, daw.WalletType),
                        new Question(3, 2, daw.DigitalAssetWallet),
                        new Question(3, 3, daw.BlockchainAddress),
                        new Question(3, 4, daw.Name)
                    )
                    const exists = formData.wallets?.some((arr) => arr.every((obj) => tempArray.some((item) => JSON.stringify(item) === JSON.stringify(obj))))

                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    if (!exists) {
                        formData.wallets?.push([...tempArray])
                    }
                })
            }
            props.nextTab()
        },
        [formData.wallets, props]
    )
    const showWalletIntakeChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            setshowWalletIntake(e.value)
        },
        []
    )

    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} elementAttr={{ 'field-id': 'wallet-intake-code-id' }}>
                <GroupItem>
                    <Item>
                        <div className="policy-info">
                            A non-custodial wallet user has sole control of their private keys, which in turn controls their digital assets and proves the funds are theirs. A
                            custodial wallet user trusts the custodian to control their private keys. Most custodial wallets these days are web-based exchange wallets.
                        </div>
                        <br></br>
                        <div className="policy-info">
                            Non-exchange wallets include both software wallets and hardware wallets. Software wallets, referred to as hot wallets, are wallets that use keys that
                            were created or stored on a device that has access to the internet (e.g. Metamask). Hardware wallets, referred to as cold wallets, are wallets that use
                            any method of storing your private keys offline, or not connected to the internet (e.g. Ledger, Exodus, Trezor).
                        </div>
                        <br></br>
                        <div className="policy-info">
                            Wallet address disclosure is solely used for the purposes of accessing source of funds and risk scoring. We do not share or disclose any information
                            listed. This is captured solely for onboarding, depositing, & withdrawing purposes.
                        </div>
                    </Item>
                    <GroupItem>
                        <CheckBox
                            value={showWalletIntake}
                            onValueChanged={showWalletIntakeChanged}
                            text="Will you be funding your account with any non-exchange wallets?"
                            disabled={formDisabled}
                            elementAttr={{ 'field-id': 'checkbox-code-id' }}
                        />
                    </GroupItem>
                </GroupItem>
                {(showWalletIntake || formDisabled) && (
                    <GroupItem caption={'Digital Asset Wallet'}>
                        <DataGrid dataSource={daws} keyExpr="ID" showBorders={true} disabled={formDisabled} elementAttr={{ 'data-field': 'datagrid-code-id' }}>
                            <Paging enabled={false} />
                            <Editing
                                mode="popup"
                                allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}
                                // selectTextOnEditStart={this.state.selectTextOnEditStart}
                                // startEditAction={this.state.startEditAction}
                            />
                            <Column dataField={'WalletType'} caption="Wallet Type" editorOptions={walletTypeEditorOptions}>
                                <Lookup dataSource={walletTypes} displayExpr="Name" valueExpr="ID" />
                                <RequiredRule />
                            </Column>
                            <Column dataField={'DigitalAssetWallet'} caption="Digital Asset Wallet" editorOptions={digitalAssetWalletEditorOptions}>
                                <Lookup dataSource={customContent.assets} displayExpr="Name" valueExpr="ID" />
                                <RequiredRule />
                            </Column>
                            <Column dataField={'BlockchainAddress'} caption="Blockchain Address" editorOptions={blockchainAddressEditorOptions}>
                                <RequiredRule />
                            </Column>
                            <Column dataField={'Name'} caption="Name (Wallet Nickname)" editorOptions={walletNameEditorOptions}>
                                <RequiredRule />
                            </Column>
                        </DataGrid>
                    </GroupItem>
                )}
                {!formDisabled && (
                    <ButtonItem>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                )}
            </Form>
        </form>
    )
}
const walletTypeEditorOptions = {
    stylingMode: 'filled',
    mode: 'text',
    elementAttr: { 'field-id': 'wallet-col-code-id' },
}
const digitalAssetWalletEditorOptions = {
    stylingMode: 'filled',
    mode: 'text',
    elementAttr: { 'field-id': 'wallet-intake-code-id' },
}
const blockchainAddressEditorOptions = {
    stylingMode: 'filled',
    mode: 'text',
}
const walletNameEditorOptions = {
    stylingMode: 'filled',
    mode: 'text',
}
const walletTypes = [
    { ID: '1', Name: 'Hardware Wallet' },
    { ID: '2', Name: 'Public Address' },
    { ID: '3', Name: 'Other' },
]

const daws: DAW[] = initializeDAW()
const submitButtonAttributes = { class: 'submit-button', 'field-id': 'submit-code-id' }

export default WalletIntakeForm
