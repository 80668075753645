import { createContext, useContext } from 'react'
import { ConfigStateEnum } from '../config/Model'
import { useConfig } from '..'
import { StylesheetsModel } from '../../clients/api/config'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ThemeProvider = (props: any): JSX.Element => {
    const { loadingCommonConfig, commonConfig } = useConfig()
    const stylesheets = commonConfig.Stylesheets
    const defaultTheme = 'light'

    if (loadingCommonConfig !== ConfigStateEnum.Loaded) return <ThemeContext.Provider value={{ ...themeContextEmpty }} {...props} />

    if (stylesheets?.Available != null && stylesheets.Available.length > 0) {
        const links = document.head.getElementsByTagName('link')
        for (const linkIdx in links) {
            const link = links[linkIdx]
            if (link.rel === 'stylesheet') {
                if (!link.href.includes('/stylesheets/')) continue

                let theme: string = stylesheets.Available[0]
                if (stylesheets.Active > 0 && stylesheets.Active < stylesheets.Available.length) theme = stylesheets.Available[stylesheets.Active]
                if (theme === defaultTheme) continue
                const baseUrl = link.href.substring(0, link.href.lastIndexOf('/'))
                link.href = `${baseUrl}/${theme}.css`
            }
        }
    }

    return <ThemeContext.Provider value={{ ...stylesheets }} {...props} />
}

const themeContextEmpty = new StylesheetsModel()
const ThemeContext = createContext(themeContextEmpty)

const useTheme = (): StylesheetsModel => useContext(ThemeContext)

export { ThemeProvider, useTheme }
