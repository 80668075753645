import { useRef, useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, CompareRule, PatternRule } from 'devextreme-react/form'
import { useAuth, useConfig } from '../../../contexts'
import notify from 'devextreme/ui/notify'
import ResetPasswordFormProps from './Model'
import { ErrorMessage, Loading, SingleCard } from '../../common'
import '../form.scss'
import { HttpErrorCodes } from '../../../clients/api/auth/Model'
import { Const } from '../../../const'
import { ErrorMessageProps } from '../../common/errorMessage/Model'

const errorMessageClear = new ErrorMessageProps()

const ResetPasswordForm = (): JSX.Element => {
    const navigate = useNavigate()
    const { resetPassword, username, loading } = useAuth()
    const { commonConfig } = useConfig()
    const [errorMessage, setErrorMessage] = useState(errorMessageClear)

    const customContent = commonConfig.Content.ResetPassword
    const customContentCommon = commonConfig.Content.Common
    const props = new ResetPasswordFormProps()
    props.user = username

    const formData = useRef({ ...props })

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (e: any): Promise<void> => {
            e.preventDefault()
            try {
                setErrorMessage(errorMessageClear)
                const response = await resetPassword(formData.current)
                if (response == null || response.code !== HttpErrorCodes.OK) {
                    setErrorMessage(new ErrorMessageProps(`Reset Password Error: ${response?.message}`, ''))
                    return
                }
                navigate('/')
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const msg = error.response?.data.error == null ? 'Reset Password Error' : error.response.data.error.message
                notify(msg, 'error', 2000)
            }
        },
        [navigate, resetPassword]
    )

    return (
        <SingleCard {...customContent}>
            <form className={'reset-password-form'} onSubmit={onSubmit}>
                <Form formData={formData.current} disabled={loading}>
                    <Item dataField={'user'} editorType={'dxTextBox'} editorOptions={emailEditorOptions}>
                        <RequiredRule message="Email is required" />
                        <PatternRule message="Email is not valid" pattern={Const.EmailValidatorPattern} />
                        <Label visible={false} />
                    </Item>
                    <Item dataField={'recoveryCode'} editorType={'dxTextBox'} editorOptions={recoveryCodeEditorOptions}>
                        <RequiredRule message="Recovery Code is required" />
                        <Label visible={false} />
                    </Item>
                    <Item dataField={'password'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
                        <RequiredRule message="Password is required" />
                        <PatternRule
                            pattern="^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                            message="At least 8 characters, 1 number, 1 uppercase character, 1 of the following !@#$%^&*"
                        />
                        <Label visible={false} />
                    </Item>
                    <Item dataField={'confirmedPassword'} editorType={'dxTextBox'} editorOptions={confirmedPasswordEditorOptions}>
                        <RequiredRule message="Password is required" />
                        <CompareRule
                            message={'Passwords do not match'}
                            comparisonType={'=='}
                            comparisonTarget={() => {
                                return formData.current.password
                            }}
                        />
                        <Label visible={false} />
                    </Item>
                    <ButtonItem>
                        <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">{loading === true ? <Loading /> : 'Continue'}</span>
                        </ButtonOptions>
                    </ButtonItem>
                    {errorMessage.message !== '' ? (
                        <Item>
                            <ErrorMessage {...errorMessage} />
                        </Item>
                    ) : null}
                    <Item>
                        <div className={'login-link'}>
                            {customContentCommon.backToLoginPrefix}
                            <Link to={'/'}>{customContentCommon.backToLoginLinkText}</Link>
                        </div>
                    </Item>
                </Form>
            </form>
        </SingleCard>
    )
}

const recoveryCodeEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'Recovery Code',
    labelMode: 'floating',
    elementAttr: {
        'field-id': 'recovery-code-id',
    },
}
const passwordEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'Password',
    mode: 'password',
    labelMode: 'floating',
    elementAttr: {
        'field-id': 'password-code-id',
    },
}
const confirmedPasswordEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'Confirm Password',
    mode: 'password',
    labelMode: 'floating',
    elementAttr: {
        'field-id': 'confirm-pass-code-id',
    },
}

const emailEditorOptions = {
    stylingMode: 'filled',
    label: 'Username',
    labelMode: 'floating',
    mode: 'email',
    elementAttr: {
        'field-id': 'email-code-id',
    },
}

export default ResetPasswordForm
