import { useCallback, useRef } from 'react'
import notify from 'devextreme/ui/notify'
import { CreateAccountTestProps } from './Model'
import Form, { Item, Label, RequiredRule, ButtonItem, ButtonOptions } from 'devextreme-react/form'
import { useAuth, useConfig } from '../../../../contexts'
import { SingleCard } from '../../../common'

const CreateAccountTest = (): JSX.Element => {
    const { createAccount } = useAuth()
    const { commonConfig } = useConfig()

    const props = new CreateAccountTestProps()

    const formData = useRef({ ...props })

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (e: any): Promise<void> => {
            e.preventDefault()
            try {
                await createAccount(JSON.parse(formData.current.json))
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const msg = error.response?.data.error == null ? 'Create Account Error, review the console error log' : error.response.data.error.message
                notify(msg, 'error', 2000)
            }
        },
        [createAccount]
    )
    const customContent = commonConfig.Content.SignUp
    return (
        <SingleCard {...customContent}>
            <form className={'create-account-form'} onSubmit={onSubmit}>
                <Form formData={formData.current} elementAttr={{ 'field-id': 'form-code-id' }}>
                    <Item dataField={'json'} editorType={'dxTextArea'} editorOptions={nameEditorOptions}>
                        <RequiredRule message="Object required" />
                        <Label visible={false} />
                    </Item>
                    <ButtonItem>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Send</span>
                        </ButtonOptions>
                    </ButtonItem>
                </Form>
            </form>
        </SingleCard>
    )
}

const nameEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'JSON object string',
    mode: 'text',
    minHeight: '400px',
    elementAttr: { 'field-id': 'name-code-id' },
}

const submitButtonAttributes = { class: 'submit-button', 'field-id': 'submit-code-id' }

export default CreateAccountTest
