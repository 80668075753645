import { SingleCardCustomizableData } from '../../common/single-card/Model'

export class ForgotPasswordFormCustomizableData extends SingleCardCustomizableData {
    constructor(public submitButtonText: string = 'Reset my password') {
        super()
    }
}
export default class ForgotPasswordFormProps {
    constructor(public email: string = '') {}
}
