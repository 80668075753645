import Form, { ButtonItem, ButtonOptions, GroupItem, Item, Label, RequiredRule } from 'devextreme-react/form'
import { useCallback } from 'react'
import { LabelTemplate } from '../../../common'
import { ICreateAccountPropsBase } from '../Model'

const CustomerProfileContactInformationForm = (props: ICreateAccountPropsBase): JSX.Element => {
    const formData = props
    const allowEdition: boolean = props.allowEdition
    const formDisabled = !allowEdition

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            e.preventDefault()
            props.nextTab()
        },
        [formData.people, props]
    )

    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} colCount={2} elementAttr={{ 'field-id': 'personal-code-id' }}>
                <GroupItem colSpan={2}>
                    <Item dataField={'people[0].addresses[0].country'} editorType={'dxSelectBox'} editorOptions={countryEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="Option is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                </GroupItem>

                <Item dataField={'people[0].addresses[0].street'} editorType={'dxTextBox'} editorOptions={addressEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Address Line 1 is required" />
                    <Label render={LabelTemplate} />
                </Item>

                <Item dataField={'people[0].addresses[0].apartment'} editorType={'dxTextBox'} editorOptions={aptEditorOptions} disabled={formDisabled}>
                    <Label render={LabelTemplate} />
                </Item>

                <GroupItem colSpan={2}>
                    <Item dataField={'people[0].addresses[0].state'} editorType={'dxSelectBox'} editorOptions={stateEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="Option is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                </GroupItem>

                <Item dataField={'people[0].addresses[0].city'} editorType={'dxTextBox'} editorOptions={cityEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Option is required" />
                    <Label render={LabelTemplate} />
                </Item>

                <Item dataField={'people[0].addresses[0].zip'} editorType={'dxTextBox'} editorOptions={zipEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Option is required" />
                    <Label render={LabelTemplate} />
                </Item>
                {/* Empty item placed here so the form can display properly in 2 columns */}
                {!formDisabled ? (
                    <ButtonItem colSpan={2}>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                ) : (
                    <></>
                )}
            </Form>
        </form>
    )
}

const countryEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'Country of Residence',
    defaultvalue: 'United States',
    mode: 'text',
    dataSource: [{ ID: 'US', Name: 'United States' }],
    displayExpr: 'Name',
    valueExpr: 'ID',
    elementAttr: {
        'field-id': 'country-code-id',
    },
}

const addressEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'Address Line 1',
    placeholder: 'Street Number,PO Box,C/O',
    mode: 'text',
    elementAttr: {
        'field-id': 'address-code-id',
    },
}
const aptEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'Address Line 2',
    placeholder: 'Apartment, Suite, Unit, Floor#',
    mode: 'text',
    elementAttr: {
        'field-id': 'apt-code-id',
    },
}
const cityEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'City',
    mode: 'text',
    elementAttr: {
        'field-id': 'city-code-id',
    },
}
const zipEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'Zip or Postal Code',
    mode: 'text',
    elementAttr: {
        'field-id': 'zip-code-id',
    },
}

const stateEditorOptions = {
    stylingMode: 'filled',
    labelTemplateText: 'State/Providence',
    mode: 'text',
    dataSource: [
        { ID: 'AL', Name: 'Alabama' },
        { ID: 'AK', Name: 'Alaska' },
        { ID: 'AS', Name: 'American Samoa' },
        { ID: 'AZ', Name: 'Arizona' },
        { ID: 'AR', Name: 'Arkansas' },
        { ID: 'CA', Name: 'California' },
        { ID: 'CO', Name: 'Colorado' },
        { ID: 'CT', Name: 'Connecticut' },
        { ID: 'DE', Name: 'Delaware' },
        { ID: 'DC', Name: 'District Of Columbia' },
        { ID: 'FM', Name: 'Federated States Of Micronesia' },
        { ID: 'FL', Name: 'Florida' },
        { ID: 'GA', Name: 'Georgia' },
        { ID: 'GU', Name: 'Guam' },
        { ID: 'HI', Name: 'Hawaii' },
        { ID: 'ID', Name: 'Idaho' },
        { ID: 'IL', Name: 'Illinois' },
        { ID: 'IN', Name: 'Indiana' },
        { ID: 'IA', Name: 'Iowa' },
        { ID: 'KS', Name: 'Kansas' },
        { ID: 'KY', Name: 'Kentucky' },
        { ID: 'LA', Name: 'Louisiana' },
        { ID: 'ME', Name: 'Maine' },
        { ID: 'MH', Name: 'Marshall Islands' },
        { ID: 'MD', Name: 'Maryland' },
        { ID: 'MA', Name: 'Massachusetts' },
        { ID: 'MI', Name: 'Michigan' },
        { ID: 'MN', Name: 'Minnesota' },
        { ID: 'MS', Name: 'Mississippi' },
        { ID: 'MO', Name: 'Missouri' },
        { ID: 'MT', Name: 'Montana' },
        { ID: 'NE', Name: 'Nebraska' },
        { ID: 'NV', Name: 'Nevada' },
        { ID: 'NH', Name: 'New Hampshire' },
        { ID: 'NJ', Name: 'New Jersey' },
        { ID: 'NM', Name: 'New Mexico' },
        { ID: 'NY', Name: 'New York' },
        { ID: 'NC', Name: 'North Carolina' },
        { ID: 'ND', Name: 'North Dakota' },
        { ID: 'MP', Name: 'Northern Mariana Islands' },
        { ID: 'OH', Name: 'Ohio' },
        { ID: 'OK', Name: 'Oklahoma' },
        { ID: 'OR', Name: 'Oregon' },
        { ID: 'PW', Name: 'Palau' },
        { ID: 'PA', Name: 'Pennsylvania' },
        { ID: 'PR', Name: 'Puerto Rico' },
        { ID: 'RI', Name: 'Rhode Island' },
        { ID: 'SC', Name: 'South Carolina' },
        { ID: 'SD', Name: 'South Dakota' },
        { ID: 'TN', Name: 'Tennessee' },
        { ID: 'TX', Name: 'Texas' },
        { ID: 'UT', Name: 'Utah' },
        { ID: 'VT', Name: 'Vermont' },
        { ID: 'VI', Name: 'Virgin Islands' },
        { ID: 'VA', Name: 'Virginia' },
        { ID: 'WA', Name: 'Washington' },
        { ID: 'WV', Name: 'West Virginia' },
        { ID: 'WI', Name: 'Wisconsin' },
        { ID: 'WY', Name: 'Wyoming' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    elementAttr: {
        'field-id': 'state-code-id',
    },
}

const submitButtonAttributes = { class: 'submit-button', 'field-id': 'submit-code-id' }
export default CustomerProfileContactInformationForm
