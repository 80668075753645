import { Routes, Route, Navigate } from 'react-router-dom'
import { useConfig } from '../contexts'
import {
    CreateAccountForm,
    CreateAccountTest,
    ForgotPasswordForm,
    LoginForm,
    NewPasswordForm,
    ResetPasswordForm,
    TwoFactorAuthRegisterForm,
    TwoFactorAuthVerifyCodeForm,
    ChangePasswordForm,
} from '../components/forms'

const AllRoutes = (): JSX.Element => {
    const { environment, commonConfig } = useConfig()
    const createAccountAvailable = commonConfig.Content.SignIn.createAnAccountType !== 'none'
    const changePasswordLink = commonConfig.Content.SignIn.changePasswordLink
    return (
        <Routes>
            <Route path="/" element={<LoginForm />} />

            {createAccountAvailable ? <Route path="/create-account" element={<CreateAccountForm />} /> : <></>}
            {createAccountAvailable && (environment === 'LOCAL' || environment === 'DEV') ? <Route path="/create-account-test" element={<CreateAccountTest />} /> : <></>}

            <Route path="/forgot-password" element={<ForgotPasswordForm />} />
            {changePasswordLink ? <Route path="/change-password" element={<ChangePasswordForm />} /> : <></>}
            <Route path="/new-password" element={<NewPasswordForm />} />
            <Route path="/reset-password" element={<ResetPasswordForm />} />

            <Route path="/two-factor-auth-register" element={<TwoFactorAuthRegisterForm />} />
            <Route path="/two-factor-auth-verify" element={<TwoFactorAuthVerifyCodeForm />} />

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}

export default AllRoutes
