import Form, { ButtonItem, ButtonOptions, Item, Label, RequiredRule } from 'devextreme-react/form'
import { useCallback, useState } from 'react'
import { LabelTemplate } from '../../../common'
import { ICreateAccountPropsBase } from '../Model'
import { CustomRule } from 'devextreme-react/data-grid'

const AcknowledgementForm = (props: ICreateAccountPropsBase): JSX.Element => {
    const formData = props
    const allowEdition: boolean = props.allowEdition
    const formDisabled = !allowEdition

    const [isValid, setIsValid] = useState(false)
    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            e.preventDefault()
            props.nextTab()
        },
        [props]
    )

    const enum validationState {
        agree = 'I Agree',
        disagree = 'I Disagree',
    }

    const validCertify = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            setIsValid(e.value === validationState.agree)
            return e.value
        },
        [setIsValid, validationState.agree]
    )
    const certifyEditorOptions = {
        stylingMode: 'filled',
        placeholder: 'Please select an option',
        labelTemplateText: 'I hereby certify that, to the best of my knowledge, the provided information is true and accurate *',
        id: 'certify',
        icon: false,
        mode: 'text',
        dataSource: [validationState.disagree, validationState.agree],
        onValueChanged: validCertify,
        elementAttr: {
            'field-id': 'certify-code-id',
        },
    }
    const signatureEditorOptions = {
        stylingMode: 'filled',
        placeholder: '',
        mode: 'text',
        labelTemplateText: 'Customer signature',
        id: 'signature',
        icon: false,
        elementAttr: {
            'field-id': 'signature-code-id',
        },
    }
    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} colCount={2} elementAttr={{ 'field-id': 'acknowledgement-code-id' }}>
                <Item colSpan={2}>
                    <div className="policy-info">
                        I affirm that all information submitted in response to this Application, including information in any attachments or exhibits is complete and accurate. I
                        understand that VaultLink will rely on the above information in determining whether to enter into any contractual agreement or other arrangement, and that
                        any false or misleading information provided would be grounds for the immediate termination of any such contractual agreement or other arrangement.
                    </div>
                    <br></br>
                    <div className="policy-info">
                        I authorize VaultLink and its employees or contractors to conduct due diligence, to the extent legally permissible and as VaultLink deems appropriate. I
                        understand that VaultLink will undertake reasonable efforts to ensure any information it gathers is kept confidential. I expressly consent to the transfer
                        of information provided in response to this Application, including any personally identifiable information, and further agree to forever release, discharge,
                        and hold harmless VaultLink, its agents, directors, employees, subsidiaries, affiliates, and representations from any claims, damages, losses, liabilities,
                        costs, and expenses (including any attorney and/or legal fees). This includes any other charge or complaint filed, arising from any legally appropriate due
                        diligence efforts undertaken and the use of any information gathered during such efforts.
                    </div>
                    <br></br>
                    <div className="policy-info">I represent and acknowledge that I have the authority to sign this Application on my behalf or my company&apos;s behalf.</div>
                </Item>
                <Item dataField={'acknowledgement.agree'} editorType={'dxSelectBox'} editorOptions={certifyEditorOptions} disabled={formDisabled}>
                    <CustomRule validationCallback={validCertify} />
                    <Label render={LabelTemplate} />
                </Item>
                <Item dataField={'acknowledgement.signature'} editorType={'dxTextBox'} editorOptions={signatureEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Signature is required" />
                    <Label render={LabelTemplate} />
                </Item>
                {!formDisabled ? (
                    <ButtonItem colSpan={2}>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true} disabled={!isValid}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                ) : (
                    <></>
                )}
            </Form>
        </form>
    )
}

const submitButtonAttributes = { class: 'submit-button', 'field-id': 'submit-code-id' }

export default AcknowledgementForm
