import { SingleCardCustomizableData } from '../common/single-card/Model'

export class CommonCustomizableData extends SingleCardCustomizableData {
    constructor(
        public backToLoginPrefix: string = 'Return to',
        public backToLoginLinkText: string = 'Sign In',
        public logoExtension: string = 'svg',
        public logoVersion: string = '?v=1',
        public layout: string = 'single',
        public inputType: string = 'floating'
    ) {
        super()
    }
}
