import ScrollView from 'devextreme-react/scroll-view'
import './singleCard.scss'
import { SingleCardProps } from './Model'
import { ReactSVG } from 'react-svg'
import { useConfig } from '../../../contexts'

const SingleCard = (props: SingleCardProps): JSX.Element => {
    const { firmImageBaseUrl } = useConfig()
    const className = 'with-footer single-card '.concat(props.className)
    const { commonConfig } = useConfig()
    const customContentCommon = commonConfig.Content.Common
    const logoExtension = customContentCommon.logoExtension
    const logoVersion = customContentCommon.logoVersion
    const viewFormat = customContentCommon.layout
    const logoImg = `${firmImageBaseUrl}/app-logo.${logoExtension}${logoVersion}`
    const hasFooter = props.footerText !== undefined && props.footerText !== ''
    let cardContainerWidth = '100%'
    if (viewFormat === 'sidebyside') {
        cardContainerWidth = '50%'
    }
    return (
        <>
            {viewFormat === 'sidebyside' && (
                <ScrollView height={'100%'} width={cardContainerWidth} className={className}>
                    <div className={'outside-logo'}>
                        <div className={'logo-container'}>
                            {logoExtension === 'svg' ? <ReactSVG src={logoImg} className="app-logo-svg" /> : <img src={logoImg} alt="App Logo" className="app-logo-img" />}
                        </div>
                    </div>
                </ScrollView>
            )}
            <ScrollView height={'100%'} width={cardContainerWidth} className={className}>
                <div className={'dx-card content'}>
                    <div className={'header'}>
                        {logoExtension === 'svg' ? <ReactSVG src={logoImg} className="app-logo-svg" /> : <img src={logoImg} alt="App Logo" className="app-logo-img" />}
                        <div className={'title'}>{props.title}</div>
                        <div className={'description'}>{props.description}</div>
                    </div>

                    {props.children}

                    {hasFooter ? (
                        <div className={'footer'}>
                            <div className={'information'}>{props.footerText}</div>
                        </div>
                    ) : null}
                </div>
            </ScrollView>
        </>
    )
}

export default SingleCard
