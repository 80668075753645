import {
    IProfile,
    Profile,
    ProductTypeEnum,
    ProfileTypeEnum,
    IAccounts,
    Accounts,
    BankAccount,
    IPerson,
    Person,
    Phone,
    Address,
    Business,
    IBusiness,
    PhoneTypeEnum,
    IQuestion,
    Question,
    Acknowledgement,
    IAcknowledgement,
    ICreateAccountPropsBase,
} from '../Model'

export interface ICreateAccountFormVaultLinkProps {
    onProfileTypeChanged: (profileType: ProfileTypeEnum) => void
    onProductTypeChanged: (productType: ProductTypeEnum) => void
    data: ICreateAccountPropsBase
}

export class CreateAccountIndividualStandardVaultLinkProps implements ICreateAccountPropsBase {
    constructor(
        public allowEdition: boolean = true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        public nextTab: () => void = () => {},
        public profile: IProfile = new Profile(ProductTypeEnum.Standard, ProfileTypeEnum.Individual),
        public accounts: IAccounts = new Accounts([new BankAccount()]),
        public people: IPerson[] = [new Person()],
        public acknowledgement: IAcknowledgement = new Acknowledgement()
    ) {
        this.people[0].phoneNumbers.push(new Phone(PhoneTypeEnum.Personal))
        this.people[0].addresses.push(new Address())
    }

    business: undefined
    partnerReferral: undefined
    digitalAssetsDetails: undefined
    wallets: undefined
}

export class CreateAccountIndividualProVaultLinkProps implements ICreateAccountPropsBase {
    constructor(
        public allowEdition: boolean = true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        public nextTab: () => void = () => {},
        public profile: IProfile = new Profile(ProductTypeEnum.Pro, ProfileTypeEnum.Individual),
        public accounts: IAccounts = new Accounts([new BankAccount()]),
        public people: IPerson[] = [new Person()],
        public digitalAssetsDetails: IQuestion[] = [new Question(1, 1, '')],
        public wallets: IQuestion[][] = [],
        public acknowledgement: IAcknowledgement = new Acknowledgement()
    ) {
        this.people[0].phoneNumbers.push(new Phone(PhoneTypeEnum.Personal))
        this.people[0].addresses.push(new Address())
        this.digitalAssetsDetails.push(new Question(1, 2, ''))
        this.digitalAssetsDetails.push(new Question(1, 3, ''))
        this.digitalAssetsDetails.push(new Question(1, 4, ''))
        this.digitalAssetsDetails.push(new Question(2, 1, ''))
        this.digitalAssetsDetails.push(new Question(2, 2, ''))
        this.digitalAssetsDetails.push(new Question(2, 3, ''))
        this.digitalAssetsDetails.push(new Question(2, 4, ''))
        this.digitalAssetsDetails.push(new Question(2, 5, ''))
    }

    business: undefined
    partnerReferral: undefined
}

export class CreateAccountBusinessStandardVaultLinkProps implements ICreateAccountPropsBase {
    constructor(
        public allowEdition: boolean = true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        public nextTab: () => void = () => {},
        public profile: IProfile = new Profile(ProductTypeEnum.Standard, ProfileTypeEnum.Business),
        public accounts: IAccounts = new Accounts([new BankAccount()]),
        public people: IPerson[] = [new Person()],
        public business: IBusiness = new Business(),
        public acknowledgement: IAcknowledgement = new Acknowledgement()
    ) {
        this.people[0].phoneNumbers.push(new Phone(PhoneTypeEnum.Personal))
        this.people[0].addresses.push(new Address())
    }

    partnerReferral: undefined
    digitalAssetsDetails: undefined
    wallets: undefined
}

export class CreateAccountBusinessProVaultLinkProps implements ICreateAccountPropsBase {
    constructor(
        public allowEdition: boolean = true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        public nextTab: () => void = () => {},
        public profile: IProfile = new Profile(ProductTypeEnum.Pro, ProfileTypeEnum.Business),
        public accounts: IAccounts = new Accounts([new BankAccount()]),
        public people: IPerson[] = [new Person()],
        public business: IBusiness = new Business(),
        public digitalAssetsDetails: IQuestion[] = [new Question(1, 1, '')],
        public wallets: IQuestion[][] = [],
        public acknowledgement: IAcknowledgement = new Acknowledgement()
    ) {
        this.people[0].phoneNumbers.push(new Phone(PhoneTypeEnum.Personal))
        this.people[0].addresses.push(new Address())
        this.digitalAssetsDetails.push(new Question(1, 2, ''))
        this.digitalAssetsDetails.push(new Question(1, 3, ''))
        this.digitalAssetsDetails.push(new Question(1, 4, ''))
        this.digitalAssetsDetails.push(new Question(2, 1, ''))
        this.digitalAssetsDetails.push(new Question(2, 2, ''))
        this.digitalAssetsDetails.push(new Question(2, 3, ''))
        this.digitalAssetsDetails.push(new Question(2, 4, ''))
        this.digitalAssetsDetails.push(new Question(2, 5, ''))
    }

    // business: undefined
    partnerReferral: undefined
    // digitalAssetsDetails: undefined
    // wallets: undefined
}
