import React, { useRef } from 'react'
import FileUploader from 'devextreme-react/file-uploader'
import { FileUploaderWithPreview } from './FileUploaderWithPreview'
import { FileUploaderEditorProps } from './Model'

export const FileUploaderEditor = React.memo(({ cellInfo }: FileUploaderEditorProps) => {
    const fileUploaderRef = useRef<FileUploader>(null)

    return (
        <>
            <FileUploaderWithPreview cellInfo={cellInfo} fileUploaderRef={fileUploaderRef} />
        </>
    )
})
FileUploaderEditor.displayName = 'FileUploadEditor'
