import { useState, createContext, useContext, useCallback } from 'react'
import { useConfig } from '..'
import { UploadFileDataModel } from '../../clients/api/uploadDocument/Model'
import { UploadDocument } from '../../clients/api/uploadDocument/api'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UploadDocumentProvider = (props: any): JSX.Element => {
    const { envConfig } = useConfig()
    const [uploadingDocument, setUploadingDocument] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const [uploadApi, setUploadApi] = useState(new UploadDocument('', '', 0))

    const baseUrl = envConfig.DocumentAPI.BaseUrl
    const apiKey = envConfig.DocumentAPI.ApiKey
    const requestTimeout = envConfig.DocumentAPI.requestTimeout

    if (!initialized && baseUrl !== '' && apiKey !== '') {
        setUploadApi(new UploadDocument(baseUrl, apiKey, requestTimeout))
        setInitialized(true)
    }

    const uploadDocumentFile = useCallback(
        async (req: UploadFileDataModel): Promise<void> => {
            try {
                setUploadingDocument(true)
                await uploadApi.Upload(req)
            } finally {
                setUploadingDocument(false)
            }
        },
        [uploadApi]
    )

    return <UploadDocumentContext.Provider value={{ uploadingDocument, uploadDocumentFile }} {...props} />
}

const UploadDocumentContext = createContext({
    uploadingDocument: false,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useUploadDocument = (): any => useContext(UploadDocumentContext)

export { UploadDocumentProvider, useUploadDocument }
