class EW {
    constructor(
        public ID: number,
        public ExchangeName: string,
        public DigitalAssetWallet: string,
        public BlockchainAddress: string,
        public Purchased: string,
        public Received: string,
        public Funding: string,
        public Upload: string
    ) {}
}

function initializeEW(): EW[] {
    return []
}

export { initializeEW, EW }
