import { SingleCardCustomizableData } from '../../common/single-card/Model'
export enum createAnAccountEnumType {
    none = 'none',
    link = 'link',
    button = 'button',
    textAndLink = 'textandlink',
}
export class LoginFormCustomizableData extends SingleCardCustomizableData {
    constructor(
        public userInputText: string = 'Username',
        public submitButtonText: string = 'Sign In',
        public forgotPasswordText: string = 'Forgot password?',
        public createAnAccountType: createAnAccountEnumType = createAnAccountEnumType.link,
        public createAnAccountText: string = 'Create an account',
        public createAnAccountTextLink: string = 'Sign up',
        public useEmailAsUsername: boolean = false,
        public forgotPasswordLink: boolean = false,
        public changePasswordLink: boolean = false,
        public showCreateAccountLinkMobile: boolean = true,
        public changePasswordText: string = 'Change Password',
        public hasRememberMe: boolean = true,
        public signInMessage: string = '',
        public signInMessageLink: string = ''
    ) {
        super()
    }
}
export class LoginFormProps {
    constructor(public username: string = '', public password: string = '', public rememberMe: boolean = false) {}
}
