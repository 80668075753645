import { useCallback, useRef, useLayoutEffect } from 'react'
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
type callbackType<A extends any[], R> = (...args: A) => R
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const useCallbackWrapper = <A extends any[], R>(callback: callbackType<A, R>): callbackType<A, R> => {
    const functionRef = useRef(callback)

    useLayoutEffect(() => {
        functionRef.current = callback
    })

    return useCallback((...args) => {
        const func = functionRef.current
        return func(...args)
    }, [])
}
