import { Tooltip } from 'devextreme-react/tooltip'

export default function LabelTemplate(data) {
    const tooltipId = '#' + data.editorOptions.id
    return (
        <>
            <div id={data.editorOptions.id}>
                {data.editorOptions.labelTemplateText}
                {data.editorOptions.icon ? <i id="helpedInfo" className="dx-icon dx-icon-info"></i> : <></>}
            </div>
            {data.editorOptions.tooltipText ? (
                <Tooltip target={tooltipId} showEvent="mouseenter" hideEvent="mouseleave" position="top">
                    <pre id="tooltip-content">{data.editorOptions.tooltipText}</pre>
                </Tooltip>
            ) : (
                <></>
            )}
        </>
    )
}
