import { v4 as uuidv4 } from 'uuid'

const RegistrationQuestionnaire = 418
export class UploadFileDataModel {
    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public File: any,
        public DocumentName: string,
        public RequestID: string = uuidv4(),
        public RelatedRecordId: number = RegistrationQuestionnaire,
        public RelatedRecordKey: number = 0,
        public Type: number = 0
    ) {}
}
