export class Asset {
    constructor(public ID: string, public Name: string) {}
}
export class SingleCardCustomizableData {
    constructor(public title: string = '', public description: string = '', public footerText = '', public className: string = '', public assets?: Asset[]) {}
}
export class SingleCardProps extends SingleCardCustomizableData {
    constructor(public children: JSX.Element) {
        super()
    }
}
