import axios, { AxiosInstance } from 'axios'
import { UploadFileDataModel } from './Model'
import { EmptyResponse } from '../auth/Model'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UploadDocument {
    private readonly axiosInstance: AxiosInstance
    private readonly uploadEndpoint = 'G2Z1N9EMFOKV710KFE9YE89O51YAVZS0MLWVMQC4WJWPD184VMSQBAOP7VKK'

    constructor(baseUrl: string, apiKey: string, requestTimeout: number) {
        this.axiosInstance = axios.create({
            baseURL: baseUrl,
            timeout: requestTimeout,
        })

        // Request Interceptor
        this.axiosInstance.interceptors.request.use(function (request) {
            request.headers.apikey = apiKey
            request.headers['Content-Type'] = 'multipart/form-data'
            return request
        })
    }

    async Upload(req: UploadFileDataModel): Promise<EmptyResponse> {
        const response = await this.axiosInstance.post(this.uploadEndpoint, { ...req })
        return response.data
    }
}
