import { SingleCardCustomizableData } from '../../common/single-card/Model'

export class ChangePasswordFormCustomizableData extends SingleCardCustomizableData {
    constructor(
        public userInputText: string = 'Email',
        public passwordInputText: string = 'Password',
        public newPasswordInputText: string = ' New Password',
        public newPasswordConfirmInputText: string = 'Confirm New Password',
        public authCodeInputText: string = 'Authentication Code',
        public submitButtonText: string = 'Reset my password',
        public changePasswordMessage: string = '',
        public changePasswordMessageLink: string = ''
    ) {
        super()
    }
}
export default class ChangePasswordFormProps {
    constructor(public user: string = '', public password: string = '', public passwordNew: string = '', public passwordNewConfirm: string = '', public code?: string) {}
}
