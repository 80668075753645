/* eslint-disable @typescript-eslint/no-empty-function */
export class StartTokenRequest {
    constructor(public id: string) {}
}

export class UsernameAuthBaseRequest {
    constructor(public username: string) {}
}

export class LoginRequest extends UsernameAuthBaseRequest {
    constructor(username: string, public password: string) {
        super(username)
    }
}

export class Verify2FARequest extends UsernameAuthBaseRequest {
    constructor(username: string, public code: string) {
        super(username)
    }
}

export class NewPasswordRequest {
    constructor(public username: string, public passwordNew: string, public passwordNewConfirm: string) {}
}

export class ChangePasswordRequest extends LoginRequest {
    constructor(username: string, password: string, public passwordNew: string, public passwordNewConfirm: string, public code?: string) {
        super(username, password)
    }
}
export class ResetPasswordRequest extends LoginRequest {
    constructor(username: string, password: string, public code: string) {
        super(username, password)
    }
}

/**
 * Enum to define the http error codes.
 * @readonly
 * @enum {number}
 */
export enum HttpErrorCodes {
    /** @member {string} */
    /** Success. */
    OK = 200,
}

export class BaseResponse {
    constructor(public code: number | undefined, public message: string | undefined) {}
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class EmptyResponse extends BaseResponse {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(code: number | undefined, message: string | undefined) {
        super(code, message)
    }
}

/**
 * Enum to define the challenge supported.
 * @readonly
 * @enum {number}
 */
export enum ChallengeEnum {
    /** @member {string} */
    /** TODO: to be implemented as challenge (cognito flow) */
    MFA_SETUP = 1,

    /** @member {string} */
    /** Register the 2FA configuring on the LM DB */
    MFA_SETUP_LM = 2,

    /** @member {string} */
    /** Validate the 2FA code */
    SOFTWARE_TOKEN_MFA = 3,

    /** @member {string} */
    /** Change the password, new password is required */
    NEW_PASSWORD_REQUIRED = 4,
}

export class AuthResponse extends BaseResponse {
    constructor(code: number | undefined, message: string | undefined, public challenge: string | undefined) {
        super(code, message)
    }
}

export class Setup2FAResponse extends BaseResponse {
    constructor(code: number | undefined, message: string | undefined, public secret: string) {
        super(code, message)
    }
}

export class UserResponse extends BaseResponse {
    constructor(code: number | undefined, message: string | undefined, public mfaEnabled: boolean) {
        super(code, message)
    }
}
