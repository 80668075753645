import { BrowserRouter } from 'react-router-dom'
import PublicContent from './PublicContent/Content'
import { ConfigProvider, ThemeProvider, AuthProvider } from './contexts'

const App = (): JSX.Element => {
    return (
        <BrowserRouter>
            <ConfigProvider>
                <AuthProvider>
                    <ThemeProvider>
                        <PublicContent />
                    </ThemeProvider>
                </AuthProvider>
            </ConfigProvider>
        </BrowserRouter>
    )
}

export default App
